








/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import BackButton from '@part/elements/BackButton.vue';


/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        components: {
            BackButton
        },
        data() {
            return {
                noBackBtnPages: ['shipping'],
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class PageMain extends Vue {};

