

























/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import lang from '@lang/finish.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        ArrowForwardSvg
    },
    props: [],
    data() {
        return {
            pageData: {},
        };
    },
    computed: {
        ...mapState(['sessionData']),
        lang(){
            return lang[this.activeLang()];
        },
    },
    methods: {
    },
    mounted() {
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
