export default {
    en: {
        title: {
            sock: 'DONE! YOUR SOCKS WILL BE KNITTED SOON',
            scarf: 'DONE! YOUR SCARF WILL BE KNITTED SOON',
        },
        intro: {
            sock: 'Please ask an employee to note down your collection number. After that it’s straight to the TextielLab to watch your socks being knitted',
            scarf: 'Please ask an employee to note down your collection number. After that it’s straight to the lab to watch your scarf being knitted'
        },
        button: 'CLOSE',
        collection: 'Collection number'
    },
    nl: {
        title: {
            sock: 'KLAAR! HET ONTWERPEN ZIT EROP',
            scarf: 'KLAAR! JE SJAAL WORDT ZO GEBREID',
        },
        intro: {
            sock: 'Ga ook nog even een kijkje nemen ' +
              'bij de machine waarmee we je sokken op een later moment breien.',
            scarf: 'Vraag je een medewerker nog even om je persoonlijke collectienummer te noteren? Dan kun je daarna naar het TextielLab om te zien hoe de sjaals worden gebreid'
        },
        button: 'AFSLUITEN',
        collection: 'Collectienummer'
    }
};
