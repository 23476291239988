







/* ----------------------------------------
        Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ArrowBackSvg from '@svg/ArrowBack.vue';

/* ----------------------------------------
        Component
---------------------------------------- */

@Component({
    components: {
        ArrowBackSvg
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
})

/* ----------------------------------------
        Export
---------------------------------------- */

export default class BackButton extends Vue {};

